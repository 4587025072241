<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap2TheGoLanguage"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 2: The Go Language" image-name="2-the-go-language.jpg" image-alt="The Go Language"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>The origin of Go: when it has been created and by whom.</p></li>
<li><p>The motivations behind the creation of Go.</p></li>
<li><p>What are the main characteristics of the language?</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Build Time</p></li>
<li><p>Statically typed language</p></li>
<li><p>Concurrency</p></li>
<li><p>Garbage collector</p></li>
<li><p>Software dependency</p></li>
</ul>
<div id="the-myth-of-creation" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> The myth of creation <a href="#the-myth-of-creation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>There is a myth around the creation of Go. The language was born inside a Google office, and it happened during a very long build that took 45 minutes.</p>
<p>This story is told by Rob Pike in <b-link class="citation" data-cites="go-at-google" href="#go-at-google" >[@go-at-google]</b-link>. It gives us a piece of precious information about the motivations behind the creation of Go. Build times were too long and painful... they had to find a way to avoid them; that was Go Genesis’ entry point.</p>
<p>Robert Griesemer, Ken Thompson, and Rob Pike are the developers that started working on Go back in 2007. Rob Pike claims that by mid-2008, the language was “mostly designed and the implementation (compiler, run-time) starting to work.”. After that, Ian Lance Taylor and Russ Cox joined the team in 2008 <b-link class="citation" data-cites="pike2009go" href="#pike2009go" >[@pike2009go]</b-link>.</p>
<p>Go is an open-source programming language maintained by its community and a core team of developers working at Google. The 16th March of 2011 is the date of the first Go release. (It was named “<strong>r56</strong>”). Go version 1 was released on the 28th March of 2012.</p>
<div id="motivations" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Motivations <a href="#motivations"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Go (or Golang) was built by Google to solve the company’s problems. To better understands the reasons, it’s worth reading the keynote talk of Rob Pike <b-link class="citation" data-cites="go-at-google" href="#go-at-google" >[@go-at-google]</b-link>.</p>
<p>What are the challenges of software at large worldwide companies?</p>
<ul>
<li><p>The codebase of Google services is massive. Google has millions of lines of code.</p></li>
<li><p>Those lines are written in different languages: C, C++, Java, and others.</p></li>
<li><p>The <strong><u>build time</u></strong> of those applications “have stretched to many minutes, even hours”.</p></li>
<li><p>Updates of some application parts can be costly.</p></li>
</ul>
<p>The objective of the first Gophers<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a> was to make the life of developers easier by :</p>
<ul>
<li><p>Reducing the build time of programs drastically.</p></li>
<li><p>Designing a language that is easy to learn, read and debug for young developers that have been exposed to C, C++, or Java.</p></li>
<li><p>Designing an effective <strong><u>dependency</u></strong> management system.</p></li>
<li><p>Building a language that can produce software that <strong><u>scale</u></strong> well, on hardware.</p></li>
</ul>
<div id="definition-of-some-concepts" class="anchor"></div>
<h2 data-number="4.1"><span class="header-section-number">4.1</span> Definition of some concepts <a href="#definition-of-some-concepts"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p><strong><u>Build time</u></strong> : the amount of time necessary for a compiler to generate a machine-readable executable.</p></li>
<li><p><strong><u>Statically Typed Language</u></strong> : Giving a precise definition of this concept is now premature. We will cover this term in the next chapters.</p></li>
<li><p><strong><u>Dependency</u></strong> : a piece of software that is used by another software.</p></li>
<li><p><strong><u>Scalability</u></strong> : the ability for a program to handle an increasing amount of tasks to be performed. For instance, a website is said to be scalable if it can accept an increasing number of requests without downtimes or increasing loading latency.</p></li>
</ul>
<div id="go-key-features" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Go key features <a href="#go-key-features"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Go creators have concentrated their efforts on several critical design choices. :</p>
<ul>
<li><p>A compiled language</p></li>
<li><p>With a semantic easy to understand and learn</p></li>
<li><p><strong><u>Statically typed</u></strong></p></li>
<li><p>With a built-in <strong><u>concurrency</u></strong> a system that is easy for developers to work on</p></li>
<li><p>With a robust dependency management</p></li>
<li><p>With a <strong><u>garbage collector</u></strong></p></li>
</ul>
<p>The main objective, as stated by Rob Pike, was to give developers an easy to learn, a language for “engineering large software projects”</p>
<div id="some-concepts" class="anchor"></div>
<h2 data-number="5.1"><span class="header-section-number">5.1</span> Some Concepts <a href="#some-concepts"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p><strong><u>Concurrency</u></strong> : A program is concurrent when tasks <strong>can</strong> be executed out-of-order or in a partial order<a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a>.</p></li>
<li><p><strong><u>Garbage collector</u></strong> (often called GC): When we build programs, we need to store data and fetch data from memory. Memory is not an infinite resource. Therefore the developer must ensure that unused elements stored in memory are destroyed from time to time. Putting some data into memory is called allocation; the inverse action, which consists of removing data from memory, is called deallocation. The garbage collector’s role is to deallocate memory when it is not used anymore. When the language does not have any garbage collector, the developer has to collect his garbage and free memory that is no longer used... By chance, Go has a garbage collector.</p></li>
</ul>
<div id="the-state-of-go" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> The State of Go <a href="#the-state-of-go"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>The project has grown very fast, and count now more than one thousand contributors<a href="#fn3" class="footnote-ref" id="fnref3" role="doc-noteref"><sup>3</sup></a>.</p></li>
<li><p>At the time of writing (8th January 2020), the latest version of Go is <strong>1.15.6</strong></p></li>
<li><p>A lot of meetups and conferences are organized to federate the community<a href="#fn4" class="footnote-ref" id="fnref4" role="doc-noteref"><sup>4</sup></a></p>
<ul>
<li><p>In 2018 there was 19 conferences organized : 3 in the US and 16 in other countries<a href="#fn5" class="footnote-ref" id="fnref5" role="doc-noteref"><sup>5</sup></a></p></li>
<li><p>In 2017 there were 13 conferences organized</p></li>
</ul></li>
<li><p>Go is wanted by developers :</p>
<ul>
<li>in 2018, 2019, and 2020 in the Stackoverflow Developer Survey Go is the top Three most wanted programming language <b-link class="citation" data-cites="dev-survy-2018" href="#dev-survy-2018" >[@dev-survy-2018]</b-link>.</li>
</ul></li>
</ul>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="7.1"><span class="header-section-number">7.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the date of birth of the Go language?</p></li>
<li><p>What does concurrency mean?</p></li>
<li><p>On average, Go shows a very long build time? True or False?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="7.2"><span class="header-section-number">7.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the date of birth of the Go language?</p>
<ol type="1">
<li>2007</li>
</ol></li>
<li><p>What does concurrency mean?</p>
<ol type="1">
<li>A program is concurrent when tasks might be executed at the same time.</li>
</ol></li>
<li><p>On average, Go show very long build time? True or False?</p>
<ol type="1">
<li>False. The language was created to tackle this precise issue.</li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Go was born in 2007</p></li>
<li><p>Go version 1 was released in 2012</p></li>
<li><p>The language is easy to understand. Its semantics remain simple.</p></li>
<li><p>It is statically typed</p></li>
<li><p>It is compiled</p></li>
<li><p>You can write concurrent programs with Go.</p></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>This is the nickname of Go programmers<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>We will dive deeper in this concept in another chapter. Definition taken from https://en.wikipedia.org/wiki/Concurrency_(computer_science)<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn3" role="doc-endnote"><p>On 13th August 2019, the Go project has 1,349 contributors<a href="#fnref3" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn4" role="doc-endnote"><p>#### see this page https://github.com/golang/go/wiki/Conferences<a href="#fnref4" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn5" role="doc-endnote"><p>those numbers have been extracted from the web page https://github.com/golang/go/wiki/Conferences, checked the 13th August 2019<a href="#fnref5" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap2TheGoLanguage"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap1ProgrammingAComputer'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Programming A Computer</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap3TheTerminal'}">
									<p><u><small>Next</small></u></p>
									<p><small>The terminal</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "The Go Language - Practical Go Lessons"
const description = "Go is an open-source programming language maintained by its community and a core team of developers working at Google. The 16th March of 2011 is the date of the first Go release Go version 1 was released on the 28th March of 2012."

export default {
  name: "Chap2TheGoLanguage",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
