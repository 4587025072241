import { render, staticRenderFns } from "./Chap2TheGoLanguage.vue?vue&type=template&id=8754e6cc&scoped=true&"
import script from "./Chap2TheGoLanguage.vue?vue&type=script&lang=js&"
export * from "./Chap2TheGoLanguage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8754e6cc",
  null
  
)

export default component.exports